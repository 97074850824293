import styled from 'styled-components';

import { fonts } from '@/theme';

export const Container = styled.div`
  column-count: 2;
  column-gap: 15px;
  padding: 10px 20px;
  @media (max-width: 991px) {
    column-count: 1;
    padding: 6px 10px;
  }
`;

export const Wrapper = styled.div`
  margin-bottom: 22px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const Text = styled.p`
  ${fonts.smallText22}
  word-break: break-word;
  ${({ indent }) => indent && 'text-indent: 2em'};
  min-height: 22px;
`;

export const Ul = styled.ul`
  margin-left: 20px;
`;

export const Li = styled.li`
  ${fonts.smallText22}
  &::marker {
    font-size: 20px;
  }
`;

export const Link = styled.a`
  ${fonts.smallText22}
`;
